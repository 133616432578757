import { useEffect, useState } from "react";
import { Api } from "../../helper/Api";
import Autocomplete from "@mui/material/Autocomplete";
import { Button, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { openForm } from "../../redux/store";
import AddIcon from "@mui/icons-material/Add";
import useFeatureEnabled from "../../helper/Feature";

export default function RSAutocomplete(props) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(
    props.multiple ? [] : { label: "", id: "" },
  );
  const [options, setOptions] = useState([]);
  const [objectId, setObjectId] = useState("");
  const callRSAutocompleteRefresh = useSelector(
    (state) => state.callRSAutocompleteRefresh,
  );
  const featureEnabled = useFeatureEnabled();

  useEffect(() => {
    refreshOptions();
  }, [callRSAutocompleteRefresh]);

  const refreshOptions = () => {
    Api.get(props.optionEndpoint, {}, setOptions);
  };

  useEffect(() => {
    if (props.defaultValue) {
      if (props.multiple) {
        const selectedOptions = options.filter((option) =>
          props.defaultValue.includes(option.id),
        );
        if (selectedOptions) {
          setValue(selectedOptions);
          setObjectId(selectedOptions.map((v) => v.id).join(","));
        } else {
          setObjectId("");
          setValue([]);
        }
      } else {
        const selectedOption = options.find(
          (option) => option.id == props.defaultValue,
        );
        if (selectedOption) {
          setValue(selectedOption);
          setObjectId(selectedOption.id);
        } else {
          setObjectId(props.defaultValue);
          setValue({ label: "", id: "" });
        }
      }
    }
  }, [options]);

  const onValueChange = (event, newValue) => {
    setValue(newValue);

    if (props.multiple) {
      const objectIds = newValue.map((v) => v.id).join(",");
      setObjectId(objectIds);
      if (props.onChange) {
        props.onChange(props.fieldName, objectIds);
      }
    } else {
      if (newValue) {
        setObjectId(newValue.id);
      } else {
        setObjectId("");
      }
      if (props.onChange) {
        props.onChange(props.fieldName, newValue);
      }
    }
  };

  const listenEnterForEnterKey = (event) => {
    if (
      event.key === "Enter" &&
      props.document === "Product" &&
      featureEnabled("products.barcode_scanner")
    ) {
      // Prevent the default action (if needed)
      event.preventDefault();

      // Stop the event from propagating
      event.stopPropagation();
    }
  };

  return (
    <Autocomplete
      value={value}
      onChange={onValueChange}
      options={options}
      freeSolo
      multiple={props.multiple}
      disabled={!!props.disabled}
      autoHighlight
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      onKeyDown={listenEnterForEnterKey}
      sx={{
        width: { tablet: "49% !important", mobile: "100%" },
        float: "left",
      }}
      renderInput={(params) => {
        return (
          <Grid container>
            <Grid mobile={10} tablet={10}>
              <TextField
                {...params}
                label={props.label}
                sx={{
                  width: {
                    tablet: "90% !important",
                    mobile: "90% !important",
                  },
                }}
              />
              <input type="hidden" name={props.fieldName} value={objectId} />
            </Grid>

            <Grid mobile={2} tablet={2}>
              {props.addEndpoint ? (
                <Button
                  variant="contained"
                  endIcon={<AddIcon />}
                  sx={{
                    mt: 1.5,
                    float: "right",
                  }}
                  onClick={(event) => {
                    dispatch(
                      openForm({
                        title: props.addTitle,
                        endpoint: props.addEndpoint,
                        formFields: props.addFormFields,
                        document: props.document,
                        apiAction: "post",
                        dispatchEvent: "callRSAutocompleteRefresh",
                      }),
                    );
                  }}
                >
                  Add
                </Button>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

RSAutocomplete.propTypes = {
  label: PropTypes.string,
  optionEndpoint: PropTypes.string,
  defaultValue: PropTypes.any,
  addFormFields: PropTypes.arrayOf(PropTypes.object),
  addTitle: PropTypes.string,
  addEndpoint: PropTypes.string,
  fieldName: PropTypes.string,
  document: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
};
