import { Api } from "../Api";
import { setBillingAddress } from "./contactForm";
import { setProductInfo } from "./productForm";

export const handleOrderFormChange = (
  fieldName,
  newValue,
  formFields,
  setFormFields,
) => {
  if (fieldName === "customer_id" && newValue) {
    Api.get(`/customers/${newValue.id}/contacts`, {}, (contacts) => {
      setBillingAddress(contacts, formFields, setFormFields);
    });
  }
  if (fieldName === "order_lines_attributes[][product_id]" && newValue) {
    Api.get(`/products/${newValue.id}`, {}, (product) => {
      setProductInfo(product, formFields, setFormFields, newValue);
    });
  }
};
