import { useState } from "react";
import { useSelector } from "react-redux";
import RSBreadcrumb from "../../components/common/RSBreadcrumb";
import ListHeader from "../../components/common/ListHeader";
import ListTable from "../../components/common/ListTable";
import { Container } from "@mui/material";

export default function OrderList() {
  const [orders, setOrders] = useState([]);
  const orderFields = useSelector((state) => state.metadata["Order"]);

  const refreshOrders = (params = {}) => {
    setOrders([]);
  };

  const updateEditFormFields = () => {};

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }} style={{ padding: 0 }}>
      <RSBreadcrumb menuStack={[{ label: "Orders", link: "/orders" }]} />
      <ListHeader
        title="Order"
        addCallback={refreshOrders}
        formFields={orderFields}
        endpoint="/orders"
        variant="h1"
        filterCallback={refreshOrders}
        document="Order"
      />
      <ListTable
        fields={orderFields}
        rows={orders}
        editEndpoint="/orders/{id}"
        editCallback={refreshOrders}
        deleteEndpoint="/orders/{id}"
        deleteCallback={refreshOrders}
        showEndpoint="/orders/{id}"
        document="Order"
        setEditFormFields={updateEditFormFields}
        title="Order"
      />
    </Container>
  );
}
