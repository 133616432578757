export const setProductInfo = (
  product,
  formFields,
  setFormFields,
  newValue,
) => {
  const newFormFields = formFields.slice();

  const nameFieldIdx = formFields.findIndex((field) =>
    field.fieldName.includes("[product_name]"),
  );
  const nameField = Object.assign({}, formFields[nameFieldIdx]);
  nameField.defaultValue = product.name;
  newFormFields[nameFieldIdx] = nameField;

  const descriptionFieldIdx = formFields.findIndex((field) =>
    field.fieldName.includes("[product_description]"),
  );
  const descriptionField = Object.assign({}, formFields[descriptionFieldIdx]);
  descriptionField.defaultValue = product.description;
  newFormFields[descriptionFieldIdx] = descriptionField;

  const priceFieldIdx = formFields.findIndex((field) =>
    field.fieldName.includes("[price]"),
  );
  const priceField = Object.assign({}, formFields[priceFieldIdx]);
  priceField.defaultValue = product.price;
  newFormFields[priceFieldIdx] = priceField;

  const productIdFieldIdx = formFields.findIndex((field) =>
    field.fieldName.includes("[product_id]"),
  );
  const productIdField = Object.assign({}, formFields[productIdFieldIdx]);
  productIdField.defaultValue = newValue;
  newFormFields[productIdFieldIdx] = productIdField;

  setFormFields(newFormFields);
};
