import { mapContactList } from "../TransformHelper";

export const setBillingAddress = (contacts, formFields, setFormFields) => {
  const newFormFields = formFields.slice();
  const addAddressFieldValues = (fieldName) => {
    const billingFieldIdx = formFields.findIndex(
      (field) => field.fieldName === fieldName,
    );
    const availableValues = mapContactList(contacts);
    const billingField = Object.assign({}, formFields[billingFieldIdx]);
    billingField.availableValues = availableValues;
    if (availableValues.length > 0) {
      billingField.defaultValue = availableValues[0].id;
    }
    newFormFields[billingFieldIdx] = billingField;
  };
  addAddressFieldValues("billing_address_id");
  addAddressFieldValues("shipping_address_id");

  setFormFields(newFormFields);
};
