import { useEffect, useState } from "react";
import { handleInvoiceFormChange } from "../../helper/fields/invoiceForm";
import { Chip, Container, Stack } from "@mui/material";
import FieldComponent from "./FieldComponent";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import { setStateEndpoint } from "../../helper/country_state";
import { handleOrderFormChange } from "../../helper/fields/orderForm";

export default function RSAssociationFieldRow(props) {
  const [fieldValues, setFieldValues] = useState({});
  const [formFieldDisplay, setFormFieldDisplay] = useState("block");
  const [detailDisplay, setDetailDisplay] = useState("none");
  const [formFields, setFormFields] = useState([]);

  const onFieldValueChange = (fieldName, newValue) => {
    setFieldValues({ ...fieldValues, [fieldName]: newValue });
    if (props.document === "InvoiceLine") {
      handleInvoiceFormChange(fieldName, newValue, formFields, setFormFields);
    } else if (props.document === "OrderLine") {
      handleOrderFormChange(fieldName, newValue, formFields, setFormFields);
    }
    if (fieldName.includes("[country]") || fieldName === "country") {
      setStateEndpoint(newValue, formFields, setFormFields);
    }
  };

  useEffect(() => {
    setFormFields(props.associationFields);
  }, [props.associationFields]);

  useEffect(() => {
    refreshDefaultValues();
  }, [formFields]);

  const refreshDefaultValues = () => {
    const defaultValues = {};
    formFields.map((field, index) => {
      defaultValues[field.fieldName] = field.defaultValue;
    });
    setFieldValues(defaultValues);
  };

  const getFieldValue = (associationField) => {
    let fieldValue = fieldValues[associationField.fieldName];
    if (associationField.fieldType === "autocomplete") {
      if (fieldValue && typeof fieldValue !== "string") {
        fieldValue = fieldValue.label;
      }
    }
    if (
      !fieldValue &&
      associationField.fieldName.includes("document_identifier")
    ) {
      fieldValue = "Auto";
    }
    return `${associationField.label}: ${fieldValue}`;
  };

  useEffect(() => {
    if (props.hide) {
      hideForm();
    } else {
      hideDetail();
    }
  }, [props.hide]);

  const hideDetail = () => {
    setFormFieldDisplay("block");
    setDetailDisplay("none");
  };

  const hideForm = () => {
    setFormFieldDisplay("none");
    setDetailDisplay("block");
  };

  return (
    <Container
      sx={{ padding: 0, maxWidth: "100%", overflow: "scroll", paddingY: 1 }}
    >
      {detailDisplay === "block" ? (
        <Stack
          direction="row"
          spacing={2}
          style={{ display: detailDisplay, cursor: "pointer" }}
          onClick={hideDetail}
        >
          {formFields.map((associationField, index) => {
            return (
              <Chip
                key={`chip-${props.rowIndex}-${index}`}
                label={getFieldValue(associationField)}
                style={{ cursor: "pointer" }}
              />
            );
          })}
        </Stack>
      ) : (
        ""
      )}

      <div style={{ display: formFieldDisplay }}>
        {formFields.map((associationField, index) => {
          return (
            <FieldComponent
              field={associationField}
              document={associationField.document}
              key={`associationFieldComponent-${associationField.fieldName}${props.rowIndex}${index}`}
              onFieldValueChange={onFieldValueChange}
            />
          );
        })}
      </div>

      {props.hide ? <Divider sx={{ marginTop: 1, width: "inherit" }} /> : ""}
    </Container>
  );
}

RSAssociationFieldRow.propTypes = {
  rowIndex: PropTypes.number,
  associationFields: PropTypes.arrayOf(PropTypes.object),
  hide: PropTypes.bool,
  document: PropTypes.string,
};
