import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import BusinessIcon from "@mui/icons-material/Business";
import PaymentsIcon from "@mui/icons-material/Payments";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SettingsIcon from "@mui/icons-material/Settings";
import ContactsIcon from "@mui/icons-material/Contacts";
import GroupIcon from "@mui/icons-material/Group";
import InventoryIcon from "@mui/icons-material/Inventory";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const taxSetupNavItems = [
  { label: "Rules", path: "/settings/taxes/rules" },
  { label: "Rates", path: "/settings/taxes/rates" },
  { label: "Classes", path: "/settings/taxes/classes" },
];

const accountSettingNavItems = [
  { label: "Delete Account", path: "/settings/account/delete_account" },
];

const settingsNavItems = [
  { label: "Company Profile", path: "/settings/company_profile" },
  { label: "Users", path: "/settings/users" },
  { label: "Document Numbering", path: "/settings/document_numbering" },
  { label: "Extend Objects", path: "/settings/extend_objects" },
  {
    label: "Custom Object",
    path: "/settings/custom_objects",
    feature: "extensions.custom_object",
  },
  { label: "Templates", path: "/settings/templates" },
  { label: "Currencies", path: "/settings/currencies" },
  { label: "Tax Setup", path: "", subItems: taxSetupNavItems },
  { label: "Unit Of Measures", path: "/settings/unit_of_measures" },
  {
    label: "Inventory Locations",
    path: "/settings/inventory_locations",
    feature: "products.inventory",
  },
  { label: "Webhooks", path: "/settings/webhooks" },
  { label: "Account", path: "", subItems: accountSettingNavItems },
];

const accountNavItems = [
  { label: "Customers List", path: "/customers", icon: <ContactsIcon /> },
  { label: "Customer Groups", path: "/customer_groups", icon: <GroupIcon /> },
];

const catalogueNavItems = [
  { label: "Products", path: "/products", icon: <CategoryIcon /> },
  {
    label: "Inventories",
    path: "/inventories",
    icon: <InventoryIcon />,
    feature: "products.inventory",
  },
  {
    label: "Purchase Orders",
    path: "/purchase_orders",
    icon: <ListAltIcon />,
    feature: "products.purchase_order",
  },
];

export const navItems = [
  { label: "Home", path: "/home", icon: <DashboardIcon /> },
  {
    label: "Catalogue",
    path: "",
    icon: <CategoryIcon />,
    subItems: catalogueNavItems,
  },
  {
    label: "Customers",
    path: "",
    icon: <BusinessIcon />,
    subItems: accountNavItems,
  },
  { label: "Payments", path: "/payments", icon: <PaymentsIcon /> },
  {
    label: "Orders",
    path: "/orders",
    icon: <BorderColorIcon />,
    feature: "products.order",
  },
  { label: "Invoices", path: "/invoices", icon: <ReceiptIcon /> },
  {
    label: "Statements",
    path: "/statements",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    label: "Custom Objects",
    path: "",
    icon: <FolderCopyIcon />,
    customObjectMenu: true,
    feature: "extensions.custom_object",
  },
  {
    label: "Settings",
    path: "",
    icon: <SettingsIcon />,
    subItems: settingsNavItems,
  },
];
